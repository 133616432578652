<template>
  <div id="app">
    <div class="demo" style="position: relative">
      <div class="loading" v-if="loading">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          style="margin: auto; background: #fff; display: block"
          width="100%"
          height="100%"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <g transform="translate(50 50)">
            <g transform="translate(-17 -17) scale(0.5)">
              <g>
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  values="0;45"
                  keyTimes="0;1"
                  dur="0.2s"
                  begin="0s"
                  repeatCount="indefinite"
                ></animateTransform>
                <path
                  d="M37.3496987939662 -7 L47.3496987939662 -7 L47.3496987939662 7 L37.3496987939662 7 A38 38 0 0 1 31.35997276079435 21.46047782418268 L31.35997276079435 21.46047782418268 L38.431040572659825 28.531545636048154 L28.531545636048154 38.431040572659825 L21.46047782418268 31.359972760794346 A38 38 0 0 1 7.0000000000000036 37.3496987939662 L7.0000000000000036 37.3496987939662 L7.000000000000004 47.3496987939662 L-6.999999999999999 47.3496987939662 L-7 37.3496987939662 A38 38 0 0 1 -21.46047782418268 31.35997276079435 L-21.46047782418268 31.35997276079435 L-28.531545636048154 38.431040572659825 L-38.43104057265982 28.531545636048158 L-31.359972760794346 21.460477824182682 A38 38 0 0 1 -37.3496987939662 7.000000000000007 L-37.3496987939662 7.000000000000007 L-47.3496987939662 7.000000000000008 L-47.3496987939662 -6.9999999999999964 L-37.3496987939662 -6.999999999999997 A38 38 0 0 1 -31.35997276079435 -21.460477824182675 L-31.35997276079435 -21.460477824182675 L-38.431040572659825 -28.531545636048147 L-28.53154563604818 -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794342 L21.460477824182686 -31.359972760794342 L28.531545636048158 -38.43104057265982 L38.4310405726598 -28.53154563604818 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23"
                  fill="#abbd81"
                ></path>
              </g>
            </g>
            <g transform="translate(0 22) scale(0.4)">
              <g>
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  values="45;0"
                  keyTimes="0;1"
                  dur="0.2s"
                  begin="-0.1s"
                  repeatCount="indefinite"
                ></animateTransform>
                <path
                  d="M37.3496987939662 -7 L47.3496987939662 -7 L47.3496987939662 7 L37.3496987939662 7 A38 38 0 0 1 31.35997276079435 21.46047782418268 L31.35997276079435 21.46047782418268 L38.431040572659825 28.531545636048154 L28.531545636048154 38.431040572659825 L21.46047782418268 31.359972760794346 A38 38 0 0 1 7.0000000000000036 37.3496987939662 L7.0000000000000036 37.3496987939662 L7.000000000000004 47.3496987939662 L-6.999999999999999 47.3496987939662 L-7 37.3496987939662 A38 38 0 0 1 -21.46047782418268 31.35997276079435 L-21.46047782418268 31.35997276079435 L-28.531545636048154 38.431040572659825 L-38.43104057265982 28.531545636048158 L-31.359972760794346 21.460477824182682 A38 38 0 0 1 -37.3496987939662 7.000000000000007 L-37.3496987939662 7.000000000000007 L-47.3496987939662 7.000000000000008 L-47.3496987939662 -6.9999999999999964 L-37.3496987939662 -6.999999999999997 A38 38 0 0 1 -31.35997276079435 -21.460477824182675 L-31.35997276079435 -21.460477824182675 L-38.431040572659825 -28.531545636048147 L-28.53154563604818 -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794342 L21.460477824182686 -31.359972760794342 L28.531545636048158 -38.43104057265982 L38.4310405726598 -28.53154563604818 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23"
                  fill="#e15b64"
                ></path>
              </g>
            </g>
            <g transform="translate(28 4) scale(0.3)">
              <g>
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  values="0;45"
                  keyTimes="0;1"
                  dur="0.2s"
                  begin="-0.1s"
                  repeatCount="indefinite"
                ></animateTransform>
                <path
                  d="M37.3496987939662 -7 L47.3496987939662 -7 L47.3496987939662 7 L37.3496987939662 7 A38 38 0 0 1 31.35997276079435 21.46047782418268 L31.35997276079435 21.46047782418268 L38.431040572659825 28.531545636048154 L28.531545636048154 38.431040572659825 L21.46047782418268 31.359972760794346 A38 38 0 0 1 7.0000000000000036 37.3496987939662 L7.0000000000000036 37.3496987939662 L7.000000000000004 47.3496987939662 L-6.999999999999999 47.3496987939662 L-7 37.3496987939662 A38 38 0 0 1 -21.46047782418268 31.35997276079435 L-21.46047782418268 31.35997276079435 L-28.531545636048154 38.431040572659825 L-38.43104057265982 28.531545636048158 L-31.359972760794346 21.460477824182682 A38 38 0 0 1 -37.3496987939662 7.000000000000007 L-37.3496987939662 7.000000000000007 L-47.3496987939662 7.000000000000008 L-47.3496987939662 -6.9999999999999964 L-37.3496987939662 -6.999999999999997 A38 38 0 0 1 -31.35997276079435 -21.460477824182675 L-31.35997276079435 -21.460477824182675 L-38.431040572659825 -28.531545636048147 L-28.53154563604818 -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794342 L21.460477824182686 -31.359972760794342 L28.531545636048158 -38.43104057265982 L38.4310405726598 -28.53154563604818 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23"
                  fill="#f8b26a"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <h1 class="h1">AI智脑大平台</h1>
      <hr />
      <p>{{ voiceText }}</p>
      <section
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div
          class="button-list"
          style="
            display: flex;
            justify-content: space-between;
            width: 200px;
            margin-bottom: 5px;
          "
        >
          <button @click="start">开始识别</button>
          <!-- <button @click="start_" :disabled="!voiceText">播放</button>
          <button @click="close_" :disabled="!voiceText">暂停</button> -->
        </div>
        <textarea
          style="outline: none; width: 500px; height: 200px"
          type="textarea"
          name="voice"
          id="voice-txt"
          v-model="resultText"
        />
      </section>

      <section class="fixed-box" id="fixed-box" v-if="fixedBox">
        <div class="fixed-main">
          <button class="fixed-close" id="close-btn" @click="close"></button>
          <div id="fixed-txt">{{ fixedTxt }}</div>
          <div class="fixed-icon">
            <img src="./assets/img/voice.png" alt="" />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Voice from "./assets/js/voice";
import HelloWorld from "./components/HelloWorld.vue";
import TTSRecorder from "./assets/onlineTTs";
export default {
  name: "App",
  components: { HelloWorld },
  data() {
    return {
      voice: null,
      fixedBox: false,
      voiceText: "", //语音识别出的文字
      resultText: "", //接口返回的结果
      fixedTxt: "Hello! 请说出你想说话。。。！",
      timer: null,
      // ----------------
      isPlaying: false,
      ttsRecorder: null,
      loading: false,
    };
  },
  mounted() {
    this.voice = new Voice({
      // 服务接口认证信息 注：apiKey 和 apiSecret 的长度都差不多，请要填错哦，！
      appId: "d4f45af5",
      apiSecret: "MTkzYjVkOWE4NTI1OTFlMDgxYjRhZDgw",
      apiKey: "82d543dd5702ded10fe134ef70fa4867",
      // 注：要获取以上3个参数，请到迅飞开放平台：https://www.xfyun.cn/services/voicedictation 【注：这是我的迅飞语音听写（流式版）每天服务量500（也就是调500次），如果你需求里大请购买服务量：https://www.xfyun.cn/services/voicedictation?target=price】
      onWillStatusChange: () => {
        //可以在这里进行页面中一些交互逻辑处理：注：倒计时（语音听写只有60s）,录音的动画，按钮交互等！
        this.fixedBox = true;
      },
      onTextChange: (text) => {
        console.log("test", text);
        //监听识别结果的变化
        this.voiceText = text;
        this.fixedTxt = text;
        // 3秒钟内没有说话，就自动关闭
        if (text) {
          console.log(text, "txttttt");
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.voice.stop(); // voice.stop();
            this.fixedBox = false;
          }, 3000);
        }
      },
    });
    this.ttsRecorder = new TTSRecorder();
  },
  methods: {
    start() {
      console.log("start");
      console.log(this.voice);
      this.voice.start();
      this.fixedBox = true;
      console.log(this.fixedBox);
    },
    close() {
      this.fixedBox = false;
      this.voice.stop();
      this.fixedBox = false;
    },
    // ---------
    start_() {
      this.setParams();
      this.ttsRecorder.start();
      // this.$nextTick(() => {
      //   this.ttsRecorder.start();
      // });
    },
    close_() {
      this.ttsRecorder.stop();
    },
    setParams() {
      this.ttsRecorder.setParams({
        voiceName: "xiaoyan",
        tte: "UTF8",
        text: this.resultText,
      });
    },
  },
  watch: {
    fixedBox: {
      handler(val) {
        if (!val && this.voiceText) {
          console.log("有值");
          this.loading = true;
          axios({
            url: "https://gpu.qingbotech.com/xask",
            method: "get",
            params: {
              input: encodeURI(this.voiceText),
            },
          }).then((res) => {
            console.log(res, "ressss");
            this.resultText = res.data;
            this.loading = false;
            this.start_(); //读出来
          });
        }
      },
      immediate: true,
    },
  },
};
</script>

<style>
@import url(./assets/css/base.css);
.test {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: inline-block;
  padding: 18px 60px;
  border: 1px solid red;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translatex(-50%);
  height: 100px;
  width: 100px;
}
</style>
